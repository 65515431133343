import React from 'react';
import styled from 'styled-components/macro';
import { PageHeader } from '../../Components/Parts';
import StyledRow from "../../Templates/StyledRow";
import StyledCol from "../../Templates/StyledCol";

const StyledMerchLink = styled.a`
    text-decoration: none;
`;

const MerchPreviewContainer = styled.div`
  text-align:center;
  display:inline-block;
  margin: 0 25px;  
`;

const MerchPreview = styled.div`
  display:inline-block;
  width:250px;
  background-color:black;
  border: 1px solid white;
  cursor: pointer;
  margin: 15px;
  padding: 10px;
  img {
    width:100%;
  }
`;

const BackWrapper = styled.div`
  text-align: center;
`;

const BackButton = styled.a`
  margin: 10px;
  cursor: pointer;
  
  text-decoration:none;
  color:#FBFBF3;
  text-transform:capitalize;
  font-size:32px;
`;

const SpotifyLink = styled.div`
  text-align: center;
  img {
    width:300px;
  }
`;

const SongTitle = styled.div`
  color:#5C7CA1;
  padding:10px;
`

const H1 = styled.h1`
  text-align:center;
  margin: 10px;
`;

function Merch() {
  const etsy = [
    {
      "url": "https://www.etsy.com/shop/LiquidFun?ref=shop-header-name&listing_id=1422379434",
      "thumbnail": "tshirt.png",
      "title": "Liquid Fun Band Etsy",
      "description": ""
    },
  ];

  const mapMerch = () => {
    return etsy.map(etsyItem => {
      return (
        <MerchPreview>
          <StyledMerchLink href={etsyItem.url} target='_blank' rel="noreferrer">
            <img src={`/merch/${etsyItem.thumbnail}`} />
            <SongTitle>{etsyItem.title}</SongTitle>
          </StyledMerchLink>
        </MerchPreview>
      );
    });
  };

  return (
    <StyledRow>
      <StyledCol title='Merch'>
        <MerchPreviewContainer>
          {mapMerch()}
        </MerchPreviewContainer>
      </StyledCol>
    </StyledRow>
  );

}

export default Merch;