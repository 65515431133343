import React, { useContext } from 'react';
import BandMember from "./BandMember";
import styled from 'styled-components/macro';
import {AuthContext} from "../../Context/AuthContext";
import AdminModal from "./admin/AdminModal";
import StyledRow from "../../Templates/StyledRow";
import StyledCol from "../../Templates/StyledCol";
import {P} from "../../Templates/P";

const BandLogoWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
`;

const BandLogo = styled.img`
  height: 200px;
`;

const BioWrapper = styled.div`
  text-align: left;
`;

function Bio() {
  const members = [
    {
      "name":"Andy Greb",
      "instruments":"Vocals, Guitar, Kazoo",
      "bio":"Andy does things",
      "picture":"andy-bio.jpeg"
    },
    {
      "name":"Nick.",
      "instruments":"Vocals, Guitar",
      "bio":"Nick does things",
      "picture":"nick-bio.jpg"
    },
    {
      "name":"Bobby Midnight",
      "instruments":"Vocals, Bass Guitar",
      "bio":"Bobby does things",
      "picture":"bobby-bio-image.jpeg"
    },
    {
      "name":"Tony Giangreco",
      "instruments":"Vocals, Drums",
      "bio":"Tony does things",
      "picture":"tony-bio-image.jpeg"
    },
  ]

  const mapBandMemberBios = () => {
    return members.map( (member, key) => {
      return (
        <StyledCol>
          <BandMember memberDetails={member} key={key}/>
        </StyledCol>
      );
    });
  };
  const { isAuthed } = useContext(AuthContext);

  return (
    <>
      <StyledRow>
        <StyledCol
          title='About the Band'
          lg={6}
          md={8}
          sm={12}
        >
          { isAuthed && <AdminModal />}
          <BioWrapper>
            <P>
              Love, it’s our favorite addiction. We love to play, we love to perform, we love to create, we love to
              build relationships, we love…
            </P>
            <P>
              We are constantly inspired by our environment and each other. Together we
              have over 50 years of musical experience and many more in life lessons.
            </P>
            <P>
              Our influences are eclectic, random, but our sound is
              certain; a touch of country pinch of folk soaking in a broth of rock n’ roll stirred with the blues. Like
              R.E.M. and Tom Petty picked up Neil Young from Bob Dylans’s garage sale.
            </P>
            <P>
              Always driven by passion we play from the heart and
              love to play live.
            </P>
          </BioWrapper>
        </StyledCol>
        <StyledCol>
          <BandLogoWrapper>
            <BandLogo src={"/pics/logo.jpeg"} alt="band logo" />
          </BandLogoWrapper>
        </StyledCol>
      </StyledRow>

      <StyledRow>
        {mapBandMemberBios()}
      </StyledRow>
    </>
  );
}

export default Bio;