import React, { useState } from 'react';
import styled from 'styled-components/macro';
import StyledRow from "../../Templates/StyledRow";
import StyledCol from "../../Templates/StyledCol";

const VideoWrapper = styled.div`
  padding: .5rem;
`

const VideoTitle = styled.div`
  padding: .5rem 0 .5rem;
  text-align: left;
  font-size: .8rem;
`

const BackWrapper = styled.div`
  text-align: center;
`;

const BackButton = styled.a`
  margin: 10px;
  cursor: pointer;
  
  text-decoration:none;
  color:#FBFBF3;
  text-transform:capitalize;
  font-size:32px;
`;

const Img = styled.img`
  width: 100%;
`

function Videos() {
  const [selectedVideo, setSelectedVideo] = useState(false);

  const videoData = [
    {
      "filename":"UhqHcO_rzEo",
      "title": "Live Demo",
      "description":""
    },
    {
      "filename":"SVveRKeKwPY",
      "title": "Lonely Passenger (Official) ",
      "description":""
    },
    {
      "filename":"Kc6qteYZ6z8",
      "title": "Rosalie",
      "description":"Official music video"
    },
    {
      "filename":"nufLtWnqiTg",
      "title": "Adriana",
      "description":"Live acoustic recording."
    },
  ];

  const mapBandVideos = () => {
    return videoData.map( (video, key) => {
      return (
        <StyledCol key={key} onClick={() => setSelectedVideo(video)} sm={6} md={4} lg={3}>
          <VideoWrapper>
            <VideoTitle>
              {video.title}
            </VideoTitle>
            <Img src={`http://img.youtube.com/vi/${video.filename}/0.jpg`}  alt={video.title}/>
            </VideoWrapper>
          </StyledCol>
      );
    });
  };

  return (
    <>
      {!selectedVideo &&
        <StyledRow>
          <StyledCol title='Youtube'>
            <StyledRow>
              {mapBandVideos()}
            </StyledRow>
          </StyledCol>
        </StyledRow>
      }

      {selectedVideo &&
        <StyledRow>
          <StyledCol title={selectedVideo.title}>
              <div
                className='video'
                style={{
                  position: "relative",
                  paddingBottom: "56.25%" /* 16:9 */,
                  paddingTop: 25,
                  height: 0
                }}>
                <iframe
                  style={{
                    position: "absolute",
                    top: 0,
                    left: 0,
                    width: "100%",
                    height: "100%"
                  }}
                  src={`https://www.youtube.com/embed/${selectedVideo.filename}`}
                  frameBorder="0"
                />
              </div>
              <BackWrapper>
                <BackButton onClick={() => setSelectedVideo(false)}>back</BackButton>
              </BackWrapper>
            </StyledCol>
        </StyledRow>
      }
    </>
  );

}

export default Videos;