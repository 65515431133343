import React, { useContext, useState } from 'react';
import styled from 'styled-components/macro';
import {AuthContext} from '../../Context/AuthContext';
import amateurHour from '../../media/amateur_hour.jpeg';
import anotherRound from '../../media/another_round.jpeg';
import oneCanShort from '../../media/one-can-short.jpg';
import SpotifyAlbum from "./components/SpotifyAlbum";
import StyledCol from "../../Templates/StyledCol";
import StyledRow from "../../Templates/StyledRow";

const CustomRow = styled.div`
  display: flex;
  padding: .5rem 1rem;
  justify-content: center;
  align-items: center;
  flex-direction: row;

  @media (max-width: 900px) {
    flex-direction: row;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ToggleModalButtonOpen = styled.button`
  display: inline-block;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-left: .2rem;
  padding-right: .2rem;
  font-size: .9rem;
  font-weight: 100;
  cursor: pointer;
  color: grey;
`

function Albums() {
  const { isAuthed } = useContext(AuthContext);
  const [showEdit, setShowEdit] = useState(false);

  const toggleEdit = () => {
    setShowEdit(!showEdit);
  };

  const CurrentSpotifyAlbums = [
    {
      title: 'Amateur Hour',
      url: 'https://open.spotify.com/album/3YsApseNMbvHp6zF6WrBlb?si=EJq7V3OSSTiuwMpP1MtD0Q',
      image: amateurHour,
    },
    {
      title: 'Another Round',
      url: 'https://open.spotify.com/album/7hWv04bPDblCx4lhtmW61i?si=lIsIo9nnSFyWS9afq1RY0A',
      image: anotherRound,
    },
    {
      title: 'One Can Short',
      url: 'https://open.spotify.com/album/30eCCGfzmeu5iZL0kPkaep?si=fwzjqQX_ReSh8UqJqCM_Hg',
      image: oneCanShort,
    },
  ];

  return (
    <StyledRow>
      <StyledCol title='Spotify'>
        { isAuthed && <ToggleModalButtonOpen onClick={toggleEdit} >[{showEdit ? 'CANCEL EDIT' : 'EDIT'}]</ToggleModalButtonOpen>}
        <>
          <CustomRow>
            {CurrentSpotifyAlbums.map( (spotifyAlbum, key) => (
              <SpotifyAlbum spotifyAlbum={spotifyAlbum} showEditButtons={showEdit}/>
            ))}
          </CustomRow>
        </>

      </StyledCol>
    </StyledRow>
  );

}

export default Albums;