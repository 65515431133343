import React from "react";
import { BrowserRouter as Router } from "react-router-dom";
import Nav from './Templates/Nav';
import './App.css';
import { AuthProvider } from './Context/AuthContext';
import Routes from './Routes';
import Parent from "./Templates/Parent";
import Footer from "./Templates/Footer";

export default function App() {
  return (
    <AuthProvider>
      <Router>
        <Parent background="#222D3B">
          <Nav />
        </Parent>
        <Parent>
          <Routes/>
        </Parent>
        <Parent background="#222D3B">
          <Footer />
        </Parent>
      </Router>
    </AuthProvider>
  );
}
