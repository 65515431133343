import React from 'react';
import SocialNetworkIcons from "../SocialNetworkIcons";
import StyledRow from "../StyledRow";
import StyledCol from "../StyledCol";
import styled from "styled-components/macro";

const NavBrand = styled.p`
  margin: 0;
  
  text-align: left;
  color: #FF7B12;
  font-size: 1rem;
  
  //@media (max-width: 1000px) {
  //  font-size: 4rem;
  //  padding: .5rem;
  //}
  //
  //@media (max-width: 800px) {
  //  font-size: 3rem;
  //  padding: .5rem;
  //}
  //
  //@media (max-width: 600px) {
  //  font-size: 2rem;
  //  padding: .5rem;
  //}
  //
  //@media (max-width: 400px) {
  //  font-size: 1rem;
  //}
`;

function Footer() {
  return (
    <StyledRow>
      <StyledCol sm={4}>
        <NavBrand>
          Liquid Fun Band
        </NavBrand>
      </StyledCol>
      <StyledCol sm={4} />
      <StyledCol sm={4}>
        <SocialNetworkIcons />
      </StyledCol>
    </StyledRow>
  );
}

export default Footer;