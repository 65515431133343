import React from 'react';
import moment from "moment";
import AnEvent from "./AnEvent";
import Openmic from "../../media/open-mic.png"
import styled from 'styled-components/macro';
import StyledRow from "../../Templates/StyledRow";
import StyledCol from "../../Templates/StyledCol";

const OpenMicBanner = styled.img`
  width: 100%;
`;

function Events() {

  const Shows = [
    {
      venueName: "Bailey's Bar & Grill",
      date: "2022-10-29T19:30:00",
      location: {
        pretty: "17731 Oak Park Ave, Tinley Park, IL, United States, Illinois",
        mapLink: "https://goo.gl/maps/ETjTFvyNZ6XsGSqEA",
      },
    },
  ];

  const daysRemaining = (date) => {
    var eventDate = moment(date);
    var todaysDate = moment();
    return eventDate.diff(todaysDate, 'days');
  };

  const processEvents = () => {
    return Shows.map(event => {
      if (daysRemaining(event.date) >= 0) {
        return (
          <AnEvent eventDetails={event}/>
        );
      } else {
        return;
      }
    });


  };

  return (
    <>
      <StyledRow>
        <StyledCol title='Events'>
          <OpenMicBanner className="" src={Openmic} alt='open mic' />
        </StyledCol>
      </StyledRow>
      <StyledRow>
        <StyledCol>
          {processEvents()}
        </StyledCol>
      </StyledRow>
    </>
  );
}

Events.propTypes = {};

export default Events;