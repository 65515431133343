import React, {useContext, useState} from 'react';
import styled from "styled-components/macro";
import {AuthContext} from "../../../Context/AuthContext";

const CustomCol = styled.div`
  padding: 0;
`

const SpotifyLink = styled.a`
  text-align: center;
`;

const SpotifyAlbumItem = styled.div`
  position: relative;
  
  background-image: ${({ image }) => `url(${image})`};
  background-size: 100% 100%;
  background-position: center;
  background-repeat: no-repeat;
  
  width: 250px;
  height: 250px;
  
  margin: 1rem;

  @media (max-width: 900px) {
    width: 200px;
    height: 200px;
  }
  
  @media (max-width: 750px) {
    width: 150px;
    height: 150px;
  }

  @media (max-width: 600px) {
    flex-direction: column;
  }
`

const ToggleModalButtonOpen = styled.button`
  cursor: pointer;
  color: grey;
  background-color: rgba(255, 255, 255, 0.8);
  border: none;
  border-radius: 4px;
  width: 100px;
  height: 25px;
  margin: 1rem;
`

const ToggleModalButtonClose = styled.div`
  display: inline-block;
  margin-left: .5rem;
  margin-right: .5rem;
  padding-left: .2rem;
  padding-right: .2rem;
  font-size: .7rem;
  font-weight: 100;
  cursor: pointer;
`

const ModalOverlay = styled.div`
  z-index: 100;
  background: rgba(20, 20, 20, .8);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
`

const ModalWrapper = styled.div`
  background: rgba(20, 20, 20, .9);
  position: fixed;
  top: 25%;
  left: 50%;
  transform: translate(-50%, -50%);
    
  border: 1px solid white;
  padding: .5rem;
`

const ModalHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ModalTitle = styled.div`
    margin: .2rem;
    font-size: 1rem;
    font-weight: bolder;
    display: inline-block;
    
    span {
      color: orange;
      font-weight: bolder;
    }
`

const ModalContent = styled.div`

`

const FormRow = styled.div`
  display: block;
  width: 100%;
  margin: .5rem 0;
`
const FormLabel = styled.label`
  margin: 0;
  padding: 0;
    display: flex;
`
const FormText = styled.p`
  padding: 0;
  min-width:100px;
  display: inline-block;
  text-align: right;
  margin-right:.5rem;
`

const EditImage = styled.img`
  width: 150px;
  height: 150px;
`;

const FormInput = styled.input`
  width: 300px;
`;

const FooterMenu = styled.div`
  display:flex;
  justify-content: space-between;
`

const FooterButton = styled.button``

function SpotifyAlbum({spotifyAlbum, showEditButtons}) {
  const { isAuthed } = useContext(AuthContext);
  const [showEdit, setShowEdit] = useState(false);

  const toggleEdit = (e) => {
    e.preventDefault();
    setShowEdit(!showEdit);
  };

  return (
    <>
      <CustomCol>
        <SpotifyLink href={spotifyAlbum.url} target="_blank">
          <SpotifyAlbumItem image={spotifyAlbum.image}>

            {isAuthed &&
              showEditButtons &&
              <ToggleModalButtonOpen onClick={(e) => toggleEdit(e)}>
                EDIT
              </ToggleModalButtonOpen>
            }
          </SpotifyAlbumItem>
        </SpotifyLink>
      </CustomCol>

      {showEdit &&
        <ModalOverlay>
          <ModalWrapper>

            <ModalHeader>
              <ModalTitle>
                Editing <span>{spotifyAlbum.title}</span>
              </ModalTitle>
              <ToggleModalButtonClose onClick={(e) => toggleEdit(e)}>
                [CLOSE]
              </ToggleModalButtonClose>
            </ModalHeader>

            <ModalContent>
              <FormRow>
                <FormLabel>
                  <FormText>TITLE :</FormText>
                  <FormInput value={spotifyAlbum.title}/>
                </FormLabel>
              </FormRow>
              <FormRow>
                <FormLabel>
                  <FormText>URL :</FormText>
                  <FormInput value={spotifyAlbum.url}/>
                </FormLabel>
              </FormRow>
              <FormRow>
                <FormLabel>
                  <FormText>IMAGE :</FormText>
                  <EditImage src={spotifyAlbum.image}/>
                </FormLabel>
              </FormRow>

              <hr/>

              <FooterMenu>
                <FooterButton>DELETE</FooterButton>
                <FooterButton>SAVE</FooterButton>
              </FooterMenu>
            </ModalContent>
          </ModalWrapper>
        </ModalOverlay>
      }
    </>
  );
}

SpotifyAlbum.propTypes = {};

export default SpotifyAlbum;
