import { Route, Switch } from 'react-router-dom';
import Home from './Home';
import Bio from './Bio';
import Events from './Events';
import Videos from './Videos';
import Albums from './Albums';
import Merch from './Merch';
import Profile from './Profile';
import Members from './Members';
import React, {useContext} from 'react';
import {AuthContext} from "../Context/AuthContext";
import Page from "../Templates/Page";

export const ALL_ROUTES = [
  {
    url: '/',
    title: null,
    comp: <Home />
  },
  {
    url: '/bio',
    title: 'Bio',
    comp: <Bio />
  },
  {
    url: '/videos',
    title: 'Videos',
    comp: <Videos />
  },
  {
    url: '/events',
    title: 'Events',
    comp: <Events />
  },
  {
    url: '/albums',
    title: 'Albums',
    comp: <Albums />
  },
  {
    url: '/merch',
    title: 'Merch',
    comp: <Merch />
  },
];

export const AUTHED_MENU = [
  {
    url: '/profile',
    title: 'Profile',
    comp: <Profile />,
  },
];

export const NOT_AUTHED_MENU = [
  {
    url: '/members',
    title: 'Members',
    comp: <Members />,
  },
];
const Routes = () => {

  const { isAuthed } = useContext(AuthContext);
  return (
    <Switch>
      {ALL_ROUTES.map( (route, key) => (
        <Route exact path={route.url} key={key}>
          <Page title={route.title}>
            { route.comp }
          </Page>
        </Route>
      ))}
      {isAuthed && AUTHED_MENU.map( (route, key) => (
        <Route exact path={route.url} key={key}>
          <Page title={route.title}>
            { route.comp }
          </Page>
        </Route>
      ))}
      {!isAuthed && NOT_AUTHED_MENU.map( (route, key) => (
        <Route exact path={route.url} key={key}>
          <Page title={route.title}>
            { route.comp }
          </Page>
        </Route>
      ))}
    </Switch>
  );
}

export default Routes;